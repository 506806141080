<template>
    <Layout title="Rules">
        <template v-slot:header>
            <div class="flex w-full sm:flex-row flex-col-reverse mt-4 sm:mt-0 sm:w-auto sm:ml-auto text-white/50 text-sm sm:space-x-4">
                <div class="flex space-x-4 mt-0">
                    <div class="flex space-x-2 px-3 my-2 py-2 bg-app-header rounded-md shadow-sm cursor-pointer select-none" @click="toggleExpandAll">
                        <span class="whitespace-nowrap">Expand All</span>
                        <div class="h-5 w-5 my-auto bg-app-bg rounded-full border-[3px] border-app-bg" :checked="expandAll" :class="{'bg-app-yellow': expandAll }"/>
                    </div>
                    <div class="flex space-x-2 px-3 my-2 py-2 bg-app-header rounded-md shadow-sm cursor-pointer select-none" @click="toggleHighlightedWords">
                        <span class="whitespace-nowrap">Highlight</span>
                        <div class="h-5 w-5 my-auto bg-app-bg rounded-full border-[3px] border-app-bg" :checked="highlightWords" :class="{'bg-app-yellow': highlightWords }"/>
                    </div>
                </div>
                <Input id="search" placeholder="Search..." v-model="search">
                    <template v-slot:before>
                        <Icon :icon="faSearch" class="absolute inset-y-0 left-0 pl-3 my-auto flex items-center pointer-events-none text-white/50"/>
                    </template>
                </Input>
            </div>
        </template>

        <div class="flex flex-col lg:flex-row-reverse center">
            <div>
                <div class="lg:w-96 py-3 px-4 lg:ml-4 mb-4 bg-app-header shadow-lg rounded-md">
                    <div class="text-xl pb-4">Changes</div>
                    <div class="space-y-4 flex flex-col">
                        <a :href="'#'+makeId(rule.target.category, rule.target.id)"
                           v-for="(rule) in changes"
                           :key="rule.id"
                           class="py-2 px-4 w-full bg-app-bg even:bg-app-header last:rounded-b-md"
                        >
                            <div>
                                A rule in <span class="text-app-yellow">{{ rule.target.category }}</span> section was
                                <span class="text-app-yellow">{{ getType(rule.name) }}</span>
                                on <span class="text-app-yellow"><TimeStamp :iso="rule.created_at" /></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="space-y-4 flex-1">
                <Disclosure
                    v-for="(name, index) in Object.keys(categories)"
                    :startOpen="expandAll || name.toLowerCase().replaceAll(' ', '_') === expanded"
                    class="bg-app-header rounded-md shadow-lg"
                    :key="name"
                >
                    <template v-slot:heading="{click, isOpen}">
                        <div @click="click" class="py-3 px-4 text-xl">
                            <Icon :icon="faCaretRight" class="mr-4 w-4 transition duration-200" :rotation="!isOpen ? null : '90'"/>

                            <span>{{ name }}</span>
                        </div>
                    </template>

                    <template v-slot:content="{changeHeight}">
                        <div>
                            <div
                                v-for="(rule, key) in categories[name]"
                                class="py-2 px-4 flex flex-row bg-app-bg even:bg-app-header last:rounded-b-md relative scroll-mt-10"
                                :key="rule.id"
                                :id="makeId(name, rule.id)"
                            >
                                <div class="absolute flex flex-row justify-around -translate-y-[110%] left-5">
                                    <ToolTip v-if="ruleCopied === rule.id" text="Copied link" :show="true"/>
                                </div>
                                <a :href="'#'+makeId(name, rule.id)" @click="copyRule(rule)" class="mr-4 w-4 text-app-yellow">
                                    {{ key + 1 }}.
                                </a>
                                <div>
                                    <h3 v-html="rule.title"/>
                                    <div class="editor">
                                        <div class="editor__content" v-if="rule.description" v-html="rule.description"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Disclosure>
            </div>
        </div>
    </Layout>
</template>

<script>
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { usePage } from '@inertiajs/inertia-vue3';
import { ref, watch } from 'vue';
import Disclosure from '../Components/Disclosure';
import Input from '../Components/Input';
import Layout from '../Components/Layout';

import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import TimeStamp from '../Components/Timestamp';
import ToolTip from '../Components/ToolTip';

export default {
    name: 'Rule',

    components: {
        TimeStamp,
        ToolTip,
        Input,
        Disclosure,
        Layout,
    },

    setup() {
        const search = ref('');
        const ruleCopied = ref();

        const value = usePage().props.value;
        const changes = ref(value.ruleChanges);
        let categories = ref(value.rules);

        let highlightWords = ref(false);

        watch(search, (newSearch) => {
            if (newSearch === '') {
                categories.value = value.rules;
                Object.entries(value.rules).forEach(([_, rules]) => {
                    rules.forEach((rule) => {
                        rule.title = rule.title.replaceAll('<mark>', '').replaceAll('</mark>', '');
                        rule.description = rule.description ? rule.description.replaceAll('<mark>', '').replaceAll('</mark>', '') : rule.description;
                    })
                })
                return;
            }

            const filteredRules = {};
            Object.entries(value.rules)
                .forEach(([name, rules]) => {
                    const filtered = rules.filter(({ title, description }) => {
                        return title.replace(/<[^>]*>?/gm, '').toLowerCase().includes(newSearch.toLowerCase()) ||
                            description?.replace(/<[^>]*>?/gm, '').toLowerCase().includes(newSearch.toLowerCase());
                    });

                    if (filtered.length !== 0) {
                        filteredRules[name] = filtered;
                        filteredRules[name].forEach((rule) => {
                            rule.title = rule.title.replaceAll('<mark>', '').replaceAll('</mark>', '');
                            rule.description = rule.description ? rule.description.replaceAll('<mark>', '').replaceAll('</mark>', '') : null;

                            if (highlightWords.value) {
                                rule.title = rule.title.replaceAll(newSearch, `<mark>${newSearch}</mark>`);
                                rule.description = rule.description ? rule.description.replaceAll(newSearch, `<mark>${newSearch}</mark>`) : null;
                            }
                        });
                    }
                });

            categories.value = filteredRules;
        });

        return {
            value,
            search,
            ruleCopied,
            faSearch,
            faCaretRight,
            categories,
            changes,
            highlightWords,
        };
    },

    data() {
        return {
            expanded: null,
            expandAll: false,
        }
    },

    methods: {
        changeHash() {
            const hash = window.location.hash.substring(1);
            this.expanded = hash.substring(0, hash.lastIndexOf('_'));
        },
        makeId(group, rule) {
            return `${group}_${rule}`.toLowerCase().replaceAll(' ', '_');
        },
        copyRule(rule) {
            this.ruleCopied = rule.id;
            setTimeout(() => {
                this.ruleCopied = null;
            }, 2000);
            navigator.clipboard.writeText(window.location.origin + window.location.pathname + '#' + this.makeId(rule.category, rule.id));
        },
        getType(type) {
            switch (type) {
                case 'Create':
                    return 'created';
                case 'Update':
                    return 'updated';
                case 'Delete':
                    return 'deleted';
            }
        },
        toggleExpandAll() {
            this.expandAll = !this.expandAll;
        },
        toggleHighlightedWords() {
            this.highlightWords = !this.highlightWords;
            if (!this.highlightWords) {
                Object.entries(this.value.rules).forEach(([_, rules]) => {
                    rules.forEach((rule) => {
                        rule.title = rule.title.replaceAll('<mark>', '').replaceAll('</mark>', '');
                        rule.description = rule.description ? rule.description.replaceAll('<mark>', '').replaceAll('</mark>', '') : null;
                    })
                })
            } else {
                if (this.search != '') {
                    Object.entries(this.value.rules).forEach(([_, rules]) => {
                        rules.forEach((rule) => {
                            rule.title = rule.title.replaceAll(this.search, `<mark>${this.search}</mark>`);
                            rule.description = rule.description ? rule.description.replaceAll(this.search, `<mark>${this.search}</mark>`) : null;
                        })
                    })
                }
            }
        },
    },

    beforeMount() {
        this.changeHash();
    },

    mounted() {
        window.addEventListener('hashchange', this.changeHash);
    },

    beforeUnmount() {
        window.removeEventListener('hashchange', this.changeHash);
    },
}
</script>

<style>
.ProseMirror {
    color: white;
}

mark {
    background-color: #f6e05e;
    color: black;
}
</style>
