@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;

@layer components {
    .header-container {
        @apply my-4 lg:my-6 mx-auto w-full flex flex-col sm:flex-row;
    }
    .header-title {
        @apply text-app-text text-5xl font-staatliches;
    }
    .subheader-title {
        @apply text-app-text text-4xl font-staatliches;
    }
    .center {
        @apply sm:px-10 px-6 max-w-screen-xl mx-auto;
    }
}

@tailwind utilities;

.overflow-var {
    overflow: var(--overflow, visible);
}
